import { Box, Placeholder } from '@energiebespaarders/symbols';
import dynamic from 'next/dynamic';
import React from 'react';
import config from '~/config';
import { themify } from '~/styles/mixins';
import type { CTASectionBlockFragment } from '~/types/generated/cms/CTASectionBlockFragment';
import type { dummy_dummy_content } from '~/types/generated/cms/dummy';
import { assertUnreachable } from 'src/typeHelpers';

const Fallback = () => <Placeholder height="200px" />;

/* eslint-disable prettier/prettier */
const AboutUsMetrics = dynamic(() => import('~/components/AboutUs/AboutUsMetrics'), { loading: Fallback });
const SavingsCheckModule = dynamic(() => import('~/components/SavingsCheck/SavingsCheckModule'), { loading: Fallback });
const CampaignContainer = dynamic(() => import('~/components/_cmsBlocks/CampaignContainer'), { loading: Fallback });
const CTACard = dynamic(() => import('~/components/_cmsBlocks/CTACard'), { loading: Fallback });
const CustomerFeedbackCarousel = dynamic(() => import('~/components/_cmsBlocks/CustomerFeedbackCarousel'), { loading: Fallback });
const FaqDropdown = dynamic(() => import('~/components/_cmsBlocks/FaqDropdown'), { loading: Fallback });
const PartnerCompanyBanner = dynamic(() => import('~/components/_cmsBlocks/PartnerCompanyBanner'), { loading: Fallback });
const PointCards = dynamic(() => import('~/components/_cmsBlocks/PointCards'), { loading: Fallback });
const PrimaryImage = dynamic(() => import('~/components/_cmsBlocks/PrimaryImage'), { loading: Fallback });
const DutyPackage = dynamic(() => import('~/components/_cmsBlocks/DutyPackage'), { loading: Fallback });
const RequestForm = dynamic(() => import('~/components/_cmsBlocks/RequestForm'), { loading: Fallback });
const Testimonial = dynamic(() => import('~/components/_cmsBlocks/Testimonial'), { loading: Fallback });

const AnchorLinks = dynamic(() => import('../_cmsBlocks/AnchorLinks'), { loading: Fallback });
const BlogPostQuickLinks = dynamic(() => import('../_cmsBlocks/BlogPostQuickLinks'), { loading: Fallback });
const CTASection = dynamic(() => import('../_cmsBlocks/CTASection'), { loading: Fallback });
const DatoButton = dynamic(() => import('../_cmsBlocks/DatoButton'), { loading: Fallback });
const DatoStepper = dynamic(() => import('../_cmsBlocks/DatoStepper'), { loading: Fallback });
const Heading = dynamic(() => import('../_cmsBlocks/Heading'), { loading: Fallback });
const ImageBlock = dynamic(() => import('../_cmsBlocks/ImageBlockRecord'), { loading: Fallback });
const LongReadQuickLink = dynamic(() => import('../_cmsBlocks/LongReadQuickLink'), { loading: Fallback });
const MiscQuickLinks = dynamic(() => import('../_cmsBlocks/MiscQuickLinks'), { loading: Fallback });
const OmniformBlock = dynamic(() => import('../_cmsBlocks/OmniformBlock'), { loading: Fallback });
const StartIntakeCard = dynamic(() => import('../_cmsBlocks/StartIntakeCard'), { loading: Fallback });
const ProductPageContent = dynamic(() => import('./ProductPageContent'), { loading: Fallback });
const TypedStructuredText = dynamic(() => import('./TypedStructuredText'), { loading: Fallback });
/* eslint-enable prettier/prettier */

export type CMSBlock = dummy_dummy_content;

// TODO: Do we want to use a Record instead?
// type PossibleBlockTypes = MyBlock['__typename'];
// export const BlockRecord: Record<PossibleBlockTypes, ReactNode> = {
//   AccordionRecord: Accordion,
//   BespaarcheckRecord
// };

export type BlockRendererOverrides = Partial<
  Record<CMSBlock['__typename'], React.FC<{ block: CMSBlock }>>
>;

interface BlockRendererProps {
  block: CMSBlock;
  overrides?: BlockRendererOverrides;
}

/**
 * Inspired by https://community.datocms.com/t/map-modular-content-field-to-react-component/2174/2
 *
 * More inspiration here https://github.com/datocms/new-website/blob/master/components/PostContent/index.js
 *
 * TODO: When there are a lot of blocks here eventually, use Next Dynamic imports
 *
 * @param props
 * @returns
 */

export function BlockRenderer({ block, overrides }: BlockRendererProps) {
  const type = block.__typename;

  if (overrides && type in overrides) {
    const Override = overrides[type]!;
    return <Override block={block} />;
  }

  switch (type) {
    case undefined:
      return <></>;

    case 'ButtonRecord':
      return <DatoButton block={block} />;

    case 'ComponentCustomerFeedbackRecord':
      return <CustomerFeedbackCarousel customers={block.customers} />;

    case 'CampaignContainerRecord':
      return <CampaignContainer block={block} />;

    case 'StructuredTextBlockRecord':
      return <TypedStructuredText data={block.structuredText} overrides={overrides} />;

    case 'AboutUsMetricsBlockRecord':
      return <AboutUsMetrics block={block} />;

    case 'BespaarcheckRecord':
      return (
        <Box width={1} py={10} style={{ background: themify('greenSlate') }}>
          <SavingsCheckModule />
        </Box>
      );

    case 'ImageBlockRecord':
      return <ImageBlock block={block} />;

    case 'ComponentDutyPackageRecord':
      return <DutyPackage block={block} />;

    case 'ComponentRequestFormStandaloneRecord':
      return <RequestForm block={block} />;

    case 'CtaCardBlockRecord':
      return <CTACard block={block} />;

    case 'CtaSectionBlockRecord':
      return <CTASection block={block as unknown as CTASectionBlockFragment} />;

    case 'HeadingRecord':
      return <Heading block={block} />;

    case 'PrimaryImageCardRecord':
      return <>PrimaryImageCardRecord</>;

    case 'PrimaryImageRecord':
      return <PrimaryImage block={block} />;

    case 'TestimonialBlockRecord':
      return <Testimonial block={block} />;

    case 'ProductPageContentRecord':
      return <ProductPageContent block={block} overrides={overrides} />;

    case 'FaqDropdownRecord':
      return <FaqDropdown block={block} />;

    case 'PartnerCompanyBannerRecord':
      return <PartnerCompanyBanner />;

    case 'PointCardsBlockRecord':
      return <PointCards block={block} />;

    case 'StepperRecord':
      return <DatoStepper block={block} />;

    case 'BlogPostQuickLinkRecord':
      return <BlogPostQuickLinks block={block} />;

    case 'MiscQuickLinkRecord':
      return <MiscQuickLinks block={block} />;

    case 'LongReadQuickLinkRecord': // TODO: refactor internals to use QuickLinks component
      return <LongReadQuickLink block={block} />;

    case 'AnchorLinkRecord':
      return <AnchorLinks block={block} />;

    case 'OmniformRecord':
      return <OmniformBlock block={block} />;

    case 'StartIntakeRecord':
      return <StartIntakeCard block={block} />;

    case 'TrusterRecord':
    case 'AnchorLinkItemRecord':
    case 'ProductHeroRecord':
    case 'QuotesOrPlanRecord':
    case 'ComponentRequestFormRecord':
      return null;

    default:
      return config.isProduction ? <></> : assertUnreachable(type);
  }
}
