import { SOLUTION_DOMAINS_NL, SolutionDomain } from '@energiebespaarders/constants';
import { Box, Container, Flex, Image } from '@energiebespaarders/symbols';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import React from 'react';
import styled from 'styled-components';
import { padding, themify } from '~/styles/mixins';
import HybridLink from '../HybridLink';
import timPv from '/public/img/homePage/timPv.png';
import roofInsulation from '/public/img/illustrations/cutouts/dakisolatie.jpg';
import insulationGlazing from '/public/img/illustrations/cutouts/isolatieglas.jpg';
import wallInsulation from '/public/img/illustrations/cutouts/spouwmuurisolatie.jpg';
import heating from '/public/img/illustrations/cutouts/verwarming.jpg';
import floorInsulation from '/public/img/illustrations/cutouts/vloerisolatie.jpg';
import pvSystem from '/public/img/illustrations/cutouts/zonnepanelen.jpg';

const StyledSection = styled.section<{ $bgColor: string }>`
  ${padding(10, 't')};
  background: ${x => x.$bgColor};
`;

export const StyledSolutionIllustration = styled(Image)`
  ${mediaMin.md} {
    max-width: 240px;
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;

    &:hover {
      transform: translateY(-6px);
    }
  }
`;

const StyledSolName = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: ${x => x.theme.type.scale[6]};
  white-space: nowrap;
`;

export const solutionDomainIllustrations: { key: SolutionDomain; src: string }[] = [
  {
    key: SolutionDomain.PvSystem,
    src: pvSystem,
  },
  {
    key: SolutionDomain.WallInsulation,
    src: wallInsulation,
  },
  {
    key: SolutionDomain.FloorInsulation,
    src: floorInsulation,
  },
  {
    key: SolutionDomain.RoofInsulation,
    src: roofInsulation,
  },
  {
    key: SolutionDomain.InsulationGlazing,
    src: insulationGlazing,
  },
  {
    key: SolutionDomain.Heating,
    src: heating,
  },
];

const Products: React.FC<{ bgColor?: string }> = ({ bgColor = 'white' }) => {
  const mobile = useIsMobile();
  const TimPhoto = () => (
    <Box width={[1, 1, '240px']} px={2}>
      <Image src={timPv} alt="Onze adviseur Tim helpt je graag!" width="240px" loading="lazy" />
    </Box>
  );
  return (
    <StyledSection $bgColor={themify(bgColor)}>
      <Container textAlign="center" px={2}>
        <h2>Alle oplossingen voor een duurzamer huis</h2>

        <Flex flexWrap="wrap" mx={[0, -2]} alignItems="flex-end">
          {!mobile && <TimPhoto />}
          <Box width={[1, 1, 'calc(100% - 240px)']} px={2} pb={[0, 2]}>
            <Flex flexWrap="wrap" mx={[0, -2]}>
              {solutionDomainIllustrations.map(ill => (
                <Box
                  key={ill.key}
                  width={[1 / 2, 1 / 3, 1 / 3, 1 / solutionDomainIllustrations.length]}
                  p={[2, 2, 3, 2]}
                  pb={[2, 2, 0, 2]}
                >
                  <HybridLink
                    to={`/woning-verduurzamen/product/${SOLUTION_DOMAINS_NL[
                      ill.key
                    ].toLowerCase()}`}
                    title={`Meer lezen over ${SOLUTION_DOMAINS_NL[ill.key].toLowerCase()}`}
                    className="link-gray"
                  >
                    <StyledSolutionIllustration
                      src={ill.src}
                      alt={`Een illustratie van ${SOLUTION_DOMAINS_NL[ill.key]}`}
                      width="100%"
                      radius="1000px"
                      mb={1}
                      loading="lazy"
                    />
                    <StyledSolName>{SOLUTION_DOMAINS_NL[ill.key]}</StyledSolName>
                  </HybridLink>
                </Box>
              ))}
            </Flex>
          </Box>
          {mobile && <TimPhoto />}
        </Flex>
      </Container>
    </StyledSection>
  );
};

export default Products;
